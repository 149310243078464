import { UNUSED } from "../utils/constant";
import { generateNewPreviewLink, getPreviewLinkList, updatePreviewLinksData } from "../api/concert.action";
import { ConcertData, DefaultFiltersForPreviewCodeTable, IPreviewLinks, IPreviewLinkStore, Store } from "../global/types";
import { makeAutoObservable, runInAction } from "mobx";

class PreviewLinkStore implements IPreviewLinkStore {
    rootStore: Store;
    concertData: ConcertData | null = null;
    allPreviewLinksList: Array<IPreviewLinks> = []
    error: string | null = null;
    loading: boolean = false;
    generateLinkLoading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    totalItems: number = 0;
    totalPages: number = 0;
    concertDuration: string = '';
    codeStatus: string = UNUSED;

    private defaultFiltersForPreviewCode: DefaultFiltersForPreviewCodeTable = {
        page: 1,
        limit: 10,
        searchTerm: '',
        codeStatus: UNUSED
    }

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    fetchConcertPreviewLinks = async (action: string, id: string, page: number, limit: number, searchTerm: string, codeStatus: string) => {
        try {
            this.setLoading(true);
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_filter':
                        this.limit = limit
                        this.codeStatus = codeStatus
                        this.page = 1
                        break;
                    default:
                        break;
                }
            })

            const params = {
                page: this.page,
                limit: this.limit,
                concert_id: id,
                search: this.searchTerm,
                status: this.codeStatus
            }

            const response = await getPreviewLinkList(params);
            const concertData = response.data?.data?.concert_data;

            runInAction(() => {
                this.allPreviewLinksList = response.data?.data?.private_links;
                this.concertData = this.formatConcertData(concertData);
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
                this.concertDuration = concertData.length;
            });
        } catch (error: any) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    generatePreviewLinks = async (concertId: string) => {
        try {
            this.setGenerateLinkLoading(true);
            await generateNewPreviewLink(concertId);

            this.setGenerateLinkLoading(false);
            await this.fetchConcertPreviewLinks('', concertId, this.page, this.limit, this.searchTerm, this.codeStatus);
        }
        catch (error: any) {
            this.setGenerateLinkLoading(false);
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    setGenerateLinkLoading = (loading: boolean) => {
        runInAction(() => {
            this.generateLinkLoading = loading;
        })
    }

    // Format concert data for the form
    formatConcertData = (data: any) => {
        const formattedGenre = data.genre.map((genre: string) => ({
            value: genre,
            label: genre.charAt(0).toUpperCase() + genre.slice(1)
        }));

        const formattedArtist = data.credits.artists.map((artist: any) => ({
            value: artist._id,
            label: artist.name
        }));

        const formattedPhotographer = data.credits.photographers.map((photographer: any) => ({
            value: photographer._id,
            label: photographer.name
        }));

        const formattedVenue = data.credits.venues.map((location: any) => ({
            value: location._id,
            label: location.name
        }));

        const formattedType = {
            value: data.type,
            label: data.type.charAt(0).toUpperCase() + data.type.slice(1).toLowerCase()
        };

        const formattedStatus = {
            value: data.status,
            label: data.status.charAt(0).toUpperCase() + data.status.slice(1).toLowerCase()
        };

        const date = new Date(data?.date);
        const formattedDate = date.toISOString().split('T')[0];

        return {
            ...data,
            genre: formattedGenre,
            artist: formattedArtist,
            location: formattedVenue,
            photographer: formattedPhotographer,
            type: formattedType,
            date: formattedDate,
            status: formattedStatus
        };
    };

    updatePreviewLinkNotes = async (linkId: string, notes: string) => {
        try {
            const data = {
                notes
            }
            await updatePreviewLinksData(linkId, data);
            await this.fetchConcertPreviewLinks('', this.concertData?._id!, this.page, this.limit, this.searchTerm, this.codeStatus);
        }
        catch (error: any) {
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }

    resetFilters = () => {
        if (!this.concertData) {
            return;
        }
        runInAction(() => {
            Object.assign(this, this.defaultFiltersForPreviewCode);
        })
        this.fetchConcertPreviewLinks('', this.concertData?._id!, this.page, this.limit, this.searchTerm, this.codeStatus);
    }

    isFilterApplied = () => {
        return Object.entries(this.defaultFiltersForPreviewCode).some(([key, defaultValue]) => {
            // Skip checking page property
            if (key === 'page') return false;

            const currentValue = this[key as keyof PreviewLinkStore];
            return currentValue !== defaultValue;
        });
    }

    resetPreviewLinkStore = () => {
        runInAction(() => {
            this.concertData = null;
            this.allPreviewLinksList = []
            this.error = null;
            this.loading = false;
            this.page = 1;
            this.searchTerm = '';
            this.limit = 10;
            this.totalItems = 0;
            this.totalPages = 0;
            this.concertDuration = '';
            this.generateLinkLoading = false;
            this.codeStatus = UNUSED;
        })
    }
}

export default PreviewLinkStore;
