import { makeAutoObservable, runInAction } from "mobx";
import { Store, IAccessCode, IAccessCodeStore, AccessCodeStatusType, DefaultFiltersForAccessCodeTable } from "../global/types";
import { UNUSED } from "../utils/constant";
import { generateNewAccessCode, getAllAccessCodes, updateNotes } from "../api/accessCode.action";

class AccessCodeStore implements IAccessCodeStore {
    rootStore: Store;
    allAccessCodeData: IAccessCode[] = [];
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    codeStatus: AccessCodeStatusType = UNUSED;
    totalItems: number = 0;
    totalPages: number = 0;

    private defaultFiltersForAccessCode: DefaultFiltersForAccessCodeTable = {
        page: 1,
        limit: 10,
        searchTerm: '',
        codeStatus: UNUSED
    }

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllAccessCodeData = async (
        action: 'change_page' | 'search' | 'change_filter' |'',
        page: number,
        searchTerm: string,
        limit: number,
        codeStatus: AccessCodeStatusType,
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_filter':
                        this.limit = limit
                        this.codeStatus = codeStatus
                        this.page = 1
                        break;
                    default:
                        break;
                }
            })
            const data = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm?.trim(),
                status: this.codeStatus.toUpperCase(),
            }
            const response = await getAllAccessCodes(data);
            runInAction(() => {
                this.allAccessCodeData = response.data?.data?.accessCodes || []
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    generateAccessCode = async () => {
        try {
            this.setLoading(true);
            await generateNewAccessCode();
            await this.fetchAllAccessCodeData('', this.page, this.searchTerm, this.limit, this.codeStatus);
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateAccessCodeNotes = async (accessCodeId: string, notes: string) => {
        try {
            this.setLoading(true)
            await updateNotes(accessCodeId, notes);
            await this.fetchAllAccessCodeData('', this.page, this.searchTerm, this.limit, this.codeStatus);
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    resetFilters = () => {
        runInAction(() => {
            Object.assign(this, this.defaultFiltersForAccessCode);
        })
        this.fetchAllAccessCodeData('', this.page, this.searchTerm, this.limit, this.codeStatus);
    }

    isFilterApplied = () => {
        return Object.entries(this.defaultFiltersForAccessCode).some(([key, defaultValue]) => {
            // Skip checking page property
            if (key === 'page') return false;

            const currentValue = this[key as keyof AccessCodeStore];
            return currentValue !== defaultValue;
        });
    }

    resetAccessCodeStore = () => {
        runInAction(() => {
            this.allAccessCodeData = [];
            this.error = null;
            this.loading = false;
            this.page = 1;
            this.searchTerm = '';
            this.limit = 10;
            this.codeStatus = '';
            this.totalItems = 0;
            this.totalPages = 0;
        })
    }
}

export default AccessCodeStore;
