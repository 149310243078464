export const IMAGE = 'IMAGE'
export const VIDEO = 'VIDEO'
export const IN_APP_IOS = 'IN_APP_IOS'
export const IN_APP_ANDROID = 'IN_APP_ANDROID'
export const CARD = 'CARD'
export const APPLE_PAY = 'APPLE_PAY'
export const GOOGLE_PAY = 'GOOGLE_PAY'
export const ALL = 'ALL'
export const PENDING = 'PENDING'
export const APPROVED = 'APPROVED'
export const REJECTED = 'REJECTED'
export const USED = 'USED'
export const UNUSED = 'UNUSED'
export const PHONE = 'PHONE'
export const GOOGLE = 'GOOGLE'
export const APPLE = 'APPLE'
export const PROCESSED = 'PROCESSED'
export const UNPROCESSED = 'UNPROCESSED'
export const SANDBOX = 'SANDBOX'
export const LIVE = 'LIVE'
export const HIDDEN = 'HIDDEN'
export const VISIBLE = 'VISIBLE'
export const SUPER_ADMIN = 'SUPER_ADMIN'
export const DRAFT_STATUS = 'DRAFT'
export const PUBLISHED_STATUS = 'PUBLISHED'
export const BREWING_STATUS = 'BREWING'
export const ARTIST = 'ARTIST'
export const VENUE = 'VENUE'
export const PHOTOGRAPHER = 'PHOTOGRAPHER'
