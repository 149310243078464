import axios, { AxiosResponse } from "axios";
import { ChartData } from "../global/types";

export const getAllTransactionsAndAmountCountData = async (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/dashboard/transactions-info`;
    return axios.get(url);
}

export const getRegisteredUserGraphData = async (params: Record<string, string | number>): Promise<ChartData[]> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/dashboard/users/graph-data`
    const response = await axios.get(url, { params })
    return response.data.data
}

export const getTransactionGraphData = async (params: Record<string, string | number>): Promise<ChartData[]> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/dashboard/transaction/graph-data`;
    const response = await axios.get(url, { params });
    return response.data.data
};

export const getDashboardSummary = async (params: Record<string, number | "all">) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/dashboard/dashboard-summary`;
    const response = await axios.get(url, { params });
    return response.data.data;
};