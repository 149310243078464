import { makeAutoObservable, runInAction } from "mobx";
import { ICustomerStore, Store, AllUserData, AllUserTransactionHistoryData, IPaymentType, DefaultFiltersForCustomerTable, DefaultFiltersForUserTransactionHistoryTable } from "../global/types";
import { changeUserPassword, getAllCustomersData, getOneUserData } from "../api/user.action";
import { getAllTransactionHistoryData } from "../api/transactionHistoryAction";
import { ALL, LIVE } from "../utils/constant";

class CustomerStore implements ICustomerStore {
    rootStore: Store;
    allCustomersData: AllUserData[] = [];
    allUserTransactionHistoryData: AllUserTransactionHistoryData[] = [];
    customerData: AllUserData | null = null;
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    logInType: string = '';
    totalItems: number = 0;
    totalPages: number = 0;
    userTransactionPage: number = 1
    userTransactionSearchTerm: string = ''
    userTransactionLimit: number = 10
    userTransactionStatus: string = '';
    userTransactionConcertTitle: string = ''
    userTransactionStartDate: string | Date = '';
    userTransactionEndDate: string | Date = '' ;
    userTransactionTotalItems: number = 0;
    userTransactionTotalPages: number = 0;
    userTransactionType: IPaymentType = ALL;
    isSandboxTransaction: string = LIVE
    customerId: string = '';
    startDate: string | Date = '';
    endDate: string | Date = '' ;

    private defaultFiltersForCustomer: DefaultFiltersForCustomerTable = {
        page: 1,
        limit: 10,
        searchTerm: '',
        startDate: '',
        endDate: '',
        logInType: ''
    }

    private defaultFiltersForUserTransactionHistory: DefaultFiltersForUserTransactionHistoryTable = {
        userTransactionPage: 1,
        userTransactionLimit: 10,
        userTransactionSearchTerm: '',
        userTransactionStartDate: '',
        userTransactionEndDate: '',
        userTransactionType: ALL,
        userTransactionConcertTitle: '',
        userTransactionStatus: '',
        isSandboxTransaction: LIVE
    }

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllCustomersData = async (
        action: 'change_page' | 'search' | 'change_limit' | 'change_filter' | '',
        page: number,
        searchTerm: string,
        limit: number,
        logInType: string,
        startDate: string | Date,
        endDate: string | Date,
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_limit':
                        this.limit = limit
                        this.page = 1
                        break;
                    case 'change_filter':
                        this.logInType = logInType
                        this.page = 1
                        this.startDate = startDate
                        this.endDate = endDate
                        break;
                    default:
                        break;
                }
            })
            const data = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm?.trim(),
                start_date: this.startDate,
                end_date: this.endDate,
                login_type: this.logInType.toUpperCase()
            }
            const response = await getAllCustomersData(data);
            runInAction(() => {
                this.allCustomersData = response.data?.data?.users || [];
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    fetchAllUserTransactionHistoryData = async (
        action: 'change_page' | 'search' | 'change_limit' | 'change_transaction_status' | 'change_concert_title' | 'change_date_range' | 'change_transaction_type' | 'change_sandbox_transaction' | '',
        userTransactionPage: number,
        userTransactionSearchTerm: string,
        userTransactionLimit: number,
        userTransactionStatus: string,
        userTransactionConcertTitle: string,
        userTransactionStartDate: string | Date,
        userTransactionEndDate: string | Date,
        transactionType: IPaymentType,
        isSandboxTransaction: string,
        customerId?: string
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.userTransactionPage = userTransactionPage;
                        break;
                    case 'search':
                        this.userTransactionSearchTerm = userTransactionSearchTerm;
                        this.userTransactionPage = 1;
                        break;
                    case 'change_limit':
                        this.userTransactionLimit = userTransactionLimit
                        this.userTransactionPage = 1
                        break;
                    case 'change_transaction_status':
                        this.userTransactionStatus = userTransactionStatus
                        this.userTransactionPage = 1
                        break;
                    case 'change_concert_title':
                        this.userTransactionConcertTitle = userTransactionConcertTitle
                        this.userTransactionPage = 1
                        break;
                    case 'change_date_range':
                        this.userTransactionStartDate = userTransactionStartDate
                        this.userTransactionEndDate = userTransactionEndDate
                        this.userTransactionPage = 1
                        break;
                    case 'change_transaction_type':
                        this.userTransactionType = transactionType
                        this.page = 1
                        break;
                    case 'change_sandbox_transaction':
                        this.isSandboxTransaction = isSandboxTransaction
                        this.page = 1
                        break;
                    default:
                        break;
                }
                this.customerId = customerId || ''
            })
            const data = {
                page: userTransactionPage,
                limit: this.userTransactionLimit,
                search: this.userTransactionSearchTerm?.trim(),
                transaction_status: this.userTransactionStatus.toUpperCase(),
                concert_id: this.userTransactionConcertTitle,
                start_date: this.userTransactionStartDate,
                end_date: this.userTransactionEndDate,
                customer_id: this.customerId,
                type: this.userTransactionType,
                is_sandbox: this.isSandboxTransaction
            }
            const response = await getAllTransactionHistoryData(data);
            runInAction(() => {
                this.allUserTransactionHistoryData = response.data?.data?.transactions || []
                this.userTransactionTotalItems = response.data?.data?.totalItems;
                this.userTransactionTotalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    fetchOneCustomerData = async (id: string) => {
        this.setLoading(true);
        try {
            const response = await getOneUserData(id);
            runInAction(() => {
                this.customerData = response.data.data;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    resetCustomerFilters = () => {
        runInAction(() => {
            Object.assign(this, this.defaultFiltersForCustomer);
        });
        this.fetchAllCustomersData('', this.page, this.searchTerm, this.limit, this.logInType, this.startDate, this.endDate);
    }

    isFilterApplied = () => {
        return Object.entries(this.defaultFiltersForCustomer).some(([key, defaultValue]) => {
            // Skip checking page property
            if (key === 'page') return false;

            const currentValue = this[key as keyof CustomerStore];
            return currentValue !== defaultValue;
        });
    }

    resetUserTransactionHistoryFilters = () => {
        runInAction(() => {
            Object.assign(this, this.defaultFiltersForUserTransactionHistory);
        });
        this.fetchAllUserTransactionHistoryData('', this.userTransactionPage, this.userTransactionSearchTerm, this.userTransactionLimit, this.userTransactionStatus, this.userTransactionConcertTitle, this.userTransactionStartDate, this.userTransactionEndDate, this.userTransactionType, this.isSandboxTransaction, this.customerId);
    }

    isUserTransactionHistoryFilterApplied = () => {
        return Object.entries(this.defaultFiltersForUserTransactionHistory).some(([key, defaultValue]) => {
            // Skip checking page property
            if (key === 'page') return false;

            const currentValue = this[key as keyof CustomerStore];
            return currentValue !== defaultValue;
        });
    }

    updateUserPassword = async (userId: string, newPassword: string) => {
        try {
            const data = {
                user_id: userId,
                new_password: newPassword
            }
            await changeUserPassword(data);
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }

    resetCustomerPageDataStates = () => {
        runInAction(() => {
            this.allCustomersData = [];
            this.allUserTransactionHistoryData = []
            this.customerData = null;
            this.error = null;
            this.loading = false;
            this.page = 1;
            this.searchTerm = '';
            this.limit = 10;
            this.logInType = '';
            this.totalItems = 0;
            this.totalPages = 0;
            this.userTransactionPage = 1
            this.userTransactionSearchTerm = ''
            this.userTransactionLimit = 10
            this.userTransactionStatus = '';
            this.userTransactionConcertTitle = ''
            this.userTransactionStartDate  = '';
            this.userTransactionEndDate  = '' ;
            this.userTransactionTotalItems = 0;
            this.userTransactionTotalPages = 0;
            this.customerId = '';
        })
    }
}

export default CustomerStore;
