import { makeAutoObservable, runInAction } from "mobx";
import { IBootlegStore, Store, CreateUserData, UserData, AllUserData, CommonDefaultFilters } from "../global/types";
import { createBootleg, getAllBootlegData, getOneUserData, updateBootlegData } from "../api/user.action";

class BootlegStore implements IBootlegStore {
    rootStore: Store;
    allBootlegData: AllUserData[] = [];
    bootlegData: UserData | null = null;
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    totalItems: number = 0;
    totalPages: number = 0;

    private defaultFiltersForBootleg: CommonDefaultFilters = {
        page: 1,
        limit: 10,
        searchTerm: ''
    }

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllBootlegData = async (
        action: 'change_page' | 'search' | 'change_limit' | '',
        page: number,
        searchTerm: string,
        limit: number
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_limit':
                        this.limit = limit
                        this.page = 1
                        break;
                    default:
                        break;
                }
            })
            const data = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm?.trim()
            }
            const response = await getAllBootlegData(data);
            runInAction(() => {
                this.allBootlegData = response.data?.data?.users || [];
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    fetchOneBootlegData = async (id: string) => {
        this.setLoading(true);
        try {
            const response = await getOneUserData(id);
            runInAction(() => {
                this.bootlegData = response.data.data;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateBootlegDetails = async (data: CreateUserData) => {
        this.setLoading(true);
        try {
            const requestBody = {
                name: data.name.trim(),
                password: data.password?.trim(),
                phone_number: data.phoneNumber.trim(),
                bootleg_id: this.bootlegData?._id,
                country_code: data.countryCode
            }

            const response = await updateBootlegData(requestBody);
            runInAction(() => {
                this.bootlegData = response.data.data;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    addNewBootleg = async (data: CreateUserData) => {
        this.setLoading(true);
        try {
            const requestBody = {
                name: data.name.trim(),
                email: data.email.trim(),
                password: data.password?.trim(),
                phone_number: data.phoneNumber.trim(),
                country_code: data.countryCode
            }

            const response = await createBootleg(requestBody);
            runInAction(() => {
                this.bootlegData = response.data.data;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    resetFilters = () => {
        runInAction(() => [
            Object.assign(this, this.defaultFiltersForBootleg)
        ])
        this.fetchAllBootlegData('', this.page, this.searchTerm, this.limit);
    }

    isFilterApplied = () => {
        return Object.entries(this.defaultFiltersForBootleg).some(([key, defaultValue]) => {
            // Skip checking page property
            if (key === 'page') return false;

            const currentValue = this[key as keyof BootlegStore];
            return currentValue !== defaultValue;
        });
    }

    resetBootlegPageDataStates = () => {
        runInAction(() => {
            this.allBootlegData = [];
            this.bootlegData = null;
            this.error = null;
            this.loading = false;
            this.page = 1;
            this.searchTerm = '';
            this.limit = 10;
            this.totalItems = 0;
            this.totalPages = 0;
        })
    }
}

export default BootlegStore;
