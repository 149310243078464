import { makeAutoObservable, runInAction } from "mobx";
import { getDashboardSummary, getRegisteredUserGraphData, getTransactionGraphData } from "../api/dashboard.action";
import { ChartData, IDashboardStore, Store } from "../global/types";

class DashboardStore implements IDashboardStore {
    rootStore: Store;
    userGraphData: ChartData[] = [];
    transactionGraphData: ChartData[] = [];
    userLoading = false;
    transactionLoading = false;
    summaryLoading = false;
    error: string | null = null;
    dayCount = 7;
    totalUsers: number = 0;
    totalTransactions: number = 0;
    totalAmount: number = 0;
    totalConcerts: number = 0;
    summaryDayCount: number | "all" = 7;

    constructor(rootStore: Store) {
        this.rootStore = rootStore
        makeAutoObservable(this);
    }

    setDayCount = (dayCount: number) => {
        this.dayCount = dayCount
        if (this.dayCount !== dayCount) {
            this.dayCount = dayCount;
            this.fetchUserGraphData(dayCount);
            this.fetchTransactionGraphData(dayCount);
        }
    }

    setSummaryDayCount = (dayCount: number | "all") => {
        this.summaryDayCount = dayCount;
        this.fetchDashboardSummary(dayCount);
    };

    setError = (error: string | null) => {
        this.error = error;
    }

    fetchUserGraphData = async (dayCount: number) => {
        this.userLoading = true;
        this.setError(null);
        try {
            const response = await getRegisteredUserGraphData({ day_count: dayCount });
            runInAction(() => {
                this.userGraphData = response;
            });
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            this.setError("Failed to load user graph data.");
        } finally {
            runInAction(() => {
                this.userLoading = false;
            });
        }
    };


    fetchTransactionGraphData = async (dayCount: number) => {
        this.transactionLoading = true;
        this.setError(null);
        try {
            const response = await getTransactionGraphData({ day_count: dayCount });
            runInAction(() => {
                this.transactionGraphData = response;
            });
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            this.setError("Failed to load transaction graph data.");
        } finally {
            runInAction(() => {
                this.transactionLoading = false;
            });
        }
    };

    fetchDashboardSummary = async (dayCount: number | "all") => {
        this.summaryLoading = true;
        this.setError(null);
        try {
            const response = await getDashboardSummary({ day_count: dayCount });
            runInAction(() => {
                this.totalUsers = response.totalUsers;
                this.totalTransactions = response.totalTransactions;
                this.totalAmount = response.totalAmount
                this.totalConcerts = response.totalConcerts
            })

        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            this.setError("Failed to load dashboard summary")
        } finally {
            runInAction(() => {
                this.summaryLoading = false;
            })
        }

    }

    resetDashboardData = (): void => {
        runInAction(() => {
            this.userGraphData = [];
            this.transactionGraphData = [];
            this.totalUsers = 0;
            this.totalTransactions = 0;
            this.totalAmount = 0;
            this.totalConcerts = 0;
            this.userLoading = false;
            this.transactionLoading = false;
            this.summaryLoading = false;
            this.error = null;
        });
    };
}

export default DashboardStore;
