import { makeAutoObservable, runInAction } from "mobx";
import { IArtistStore, Store, CreateArtistData, UserData, AllUserData, CommonDefaultFilters, UpdateArtistData } from "../global/types";
import { checkIfSlugIsAvailable, createArtist, getAllArtistData, getOneUserData, getPreSignedImageUrl, updateArtistData, uploadFileToS3 } from "../api/user.action";
import { deleteCredit } from "../api/credit.action";
import { ARTIST } from "../utils/constant";

class ArtistStore implements IArtistStore {
    rootStore: Store;
    allArtistData: AllUserData[] = [];
    artistData: UserData | null = null;
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    totalItems: number = 0;
    totalPages: number = 0;

    private defaultFiltersForArtist: CommonDefaultFilters = {
        page: 1,
        limit: 10,
        searchTerm: ''
    }

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllArtistData = async (
        action: 'change_page' | 'search' | 'change_limit' | '',
        page: number,
        searchTerm: string,
        limit: number
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_limit':
                        this.limit = limit
                        this.page = 1
                        break;
                    default:
                        break;
                }
            })
            const data = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm?.trim()
            }
            const response = await getAllArtistData(data);
            runInAction(() => {
                this.allArtistData = response.data?.data?.users || [];
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    fetchOneArtistData = async (id: string) => {
        this.setLoading(true);
        try {
            const response = await getOneUserData(id);
            runInAction(() => {
                this.artistData = response.data.data;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateArtistDetails = async (data: CreateArtistData) => {
        this.setLoading(true);
        try {
            const requestBody: UpdateArtistData = {
                name: data.name,
                image: this.getUrlEndpoint(data.image),
                artist_id: this.artistData?._id!
            }
            if (data.bio) {
                requestBody['bio'] = data.bio;
            }

            const response = await updateArtistData(requestBody);
            runInAction(() => {
                this.artistData = response.data.data;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    addNewArtist = async (data: CreateArtistData) => {
        this.setLoading(true);
        try {
            const requestBody: CreateArtistData = {
                name: data.name,
                image: data.image,
                slug: data.slug
            }
            if (data.bio) {
                requestBody['bio'] = data.bio;
            }
            const response = await createArtist(requestBody);
            runInAction(() => {
                this.artistData = response.data.data;

            })
            return response.data.data
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    deleteArtist = async (id: string) => {
        try {
            await deleteCredit(id, ARTIST);
            if (this.page > 1 && this.allArtistData.length === 1) {
                runInAction(() => {
                    this.page -= 1;
                })
            }
            await this.fetchAllArtistData('', this.page, this.searchTerm, this.limit);
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }

    resetArtistPageDataStates = () => {
        runInAction(() => {
            this.allArtistData = [];
            this.artistData = null;
            this.error = null;
            this.loading = false;
            this.page = 1;
            this.searchTerm = '';
            this.limit = 10;
            this.totalItems = 0;
            this.totalPages = 0;
        })
    }

    getUrlEndpoint = (fullUrl: string) => {
        try {
            const url = new URL(fullUrl);
            const endpoint = url.pathname.startsWith("/") ? url.pathname.slice(1) : url.pathname;
            return endpoint;
        } catch (error) {
            return fullUrl;
        }
    };

    uploadImage = async (file: File, user_type: string): Promise<string> => {
        try {
            const response = await getPreSignedImageUrl(file.name, file.type, user_type);
            const { presigned_url, file_name } = response.data.data;

            await uploadFileToS3(presigned_url, file, file.type);

            return file_name;
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            throw error;
        }
    }

    resetFilters = () => {
        runInAction(() => {
            Object.assign(this, this.defaultFiltersForArtist);
        })
        this.fetchAllArtistData('', this.page, this.searchTerm, this.limit);
    }

    isFilterApplied = () => {
        return Object.entries(this.defaultFiltersForArtist).some(([key, defaultValue]) => {
            // Skip checking page property
            if (key === 'page') return false;

            const currentValue = this[key as keyof ArtistStore];
            return currentValue !== defaultValue;
        });
    }

    verifyIfSlugIsAvailable = async (slug: string, artistId?: string) => {
        try {
            const data: {
                slug: string,
                credit_id?: string
            } = { slug };

            if (artistId) {
                data['credit_id'] = artistId;
            }
            await checkIfSlugIsAvailable(data);
            return { isAvailable: true };
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            return { isAvailable: false };
        }
    }
}

export default ArtistStore;
