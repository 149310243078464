import { toast, ToastOptions, Id, ToastContent } from 'react-toastify';
import { AxiosError } from 'axios';
import 'react-toastify/dist/ReactToastify.css';

// Use a Set to track active toasts
const activeToasts = new Set<Id>();

const defaultOptions: ToastOptions = {
};

const dismissAllToasts = () => {
  activeToasts.forEach((id) => {
    toast.dismiss(id);
  });
  activeToasts.clear();
};

const showToast = (
  message: ToastContent, 
  type: 'success' | 'error' | 'info' | 'warning', 
  options?: ToastOptions
) => {
  // Dismiss all existing toasts
  dismissAllToasts();

  // Show new toast
  const toastOptions: ToastOptions = {
    ...defaultOptions,
    ...options,
  };

  const id = toast[type](message, toastOptions);

  // Only add valid IDs to the Set
  if (id) {
    activeToasts.add(id);
  }

  return id;
};

const success = (message: ToastContent, options?: ToastOptions) => {
  return showToast(message, 'success', options);
};

const error = (message: ToastContent, options?: ToastOptions) => {
  return showToast(message, 'error', options);
};

const info = (message: ToastContent, options?: ToastOptions) => {
  return showToast(message, 'info', options);
};

const warning = (message: ToastContent, options?: ToastOptions) => {
  return showToast(message, 'warning', options);
};

const dismiss = () => {
  dismissAllToasts();
};

const handleError = (err: unknown) => {
  if (err instanceof AxiosError) {
    const errorMessage = err.response?.data?.message || err.message || 'Something went wrong';
    error(errorMessage);
  } else if (err instanceof Error) {
    error(err.message);
  } else {
    error('Something went wrong. Please try again.');
  }
};

export const toastManager = {
  success,
  error,
  info,
  warning,
  dismiss,
  handleError
};