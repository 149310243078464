import React, { useContext, useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import Header from "./commonComponents/Header";
import Sidebar from "./commonComponents/Sidebar";
import { RootContext } from "../context";
import { observer } from "mobx-react-lite";
import { toastManager } from ".././utils/toastManager";
import Loader from "./commonComponents/Loader";

const Layout: React.FC = observer(() => {
  const { authStore } = useContext(RootContext);
  const { isAuthenticated, fetchUserProfile } = authStore;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [loading, serLoading] = useState(true);

  useEffect(() => {
    const getServerVersion = async () => {
      try {
        serLoading(true)
        await fetchUserProfile()
      } catch (error: unknown) {
        toastManager.handleError(error);
      } finally {
        serLoading(false)
      }
    }
    getServerVersion()
  }, [fetchUserProfile])

  const toggleSidebar = (value: boolean) => {
    setIsSidebarOpen(value);
  };

  if (loading) {
    return <Loader />
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="w-screen h-screen">
      <Header toggleSidebar={toggleSidebar} />
      <div className="flex-grow flex overflow-hidden">
        <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
        {isSidebarOpen && (
          <div
            onClick={() => setIsSidebarOpen(false)}
            className="fixed inset-0 bg-black opacity-30 transition-all duration-300 ease-in-out z-30 md:hidden"
          ></div>
        )}
        <main className="w-full relative overflow-y-auto h-[calc(100vh-48px)] overflow-x-hidden px-4 py-6 md:px-10">
          <Outlet />
        </main>
      </div>
    </div>
  );
});

export default Layout;
