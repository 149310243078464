import axios, { AxiosResponse } from "axios";
import { Dictionary } from "../global/types";

export const getAllFeedsData = async (params: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/feed/get-posts-list`;
    return axios.get(url, { params });
}

export const approveFeedPost = async (postId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/feed/approve/${postId}`;
    return axios.post(url);
}

export const rejectFeedPost = async (postId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/feed/reject/${postId}`;
    return axios.post(url);
}

export const assignUserToFeedPost = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/feed/assign-user`;
    return axios.post(url, data);
}