import { makeAutoObservable, observable, runInAction } from "mobx";
import { IConcertStore, Store, ConcertData, Genre, CreateConcertData, TrackSplittingType, AllConcertData, PriceDataForConcert, verifySlugData, MediaItem, ConcertVisibleType, DefaultFiltersForConcertTable, ConcertTitle, resetGalleryPage, PostStatus, ProcessingStatus, MediaSettings } from "../global/types";
import { createConcert, deleteConcert, deleteGalleryImage, getAllConcertData, getAllConcertTitles, getAllPricesForConcertData, getConcertGallery, getConcertTrackSplitData, getOneConcertData, getPreSignedUrlAudio, getPreSignedUrlImage, getTotalUsers, saveMediaSettings, setDefaultGalleryImage, toggleIsEnable, updateConcertData, updateConcertWeight, updateGallery, updateTrackSplitData, uploadFileToS3, verifyUniqueSlug } from "../api/concert.action";
import { getOneUserData } from "../api/user.action"
import { ALL, HIDDEN, VISIBLE } from "../utils/constant";
import { approveFeedPost, assignUserToFeedPost, rejectFeedPost } from "../api/feed.action";
class ConcertStore implements IConcertStore {
    rootStore: Store;
    allConcertData: AllConcertData[] = [];
    concertData: ConcertData | null = null;
    trackSplitting: TrackSplittingType[] = [];
    gallery: MediaItem[] = [];
    priceDataForConcert: PriceDataForConcert[] = []
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    concertType: string = 'All';
    concertStatus: string = 'All';
    totalItems: number = 0;
    totalPages: number = 0;
    audioUploadProgress: number = 0;
    isAudioUploading: boolean = false;
    audioUploadComplete: boolean = false;
    audioURL: string | null = null;
    concertDuration: string | null = null;
    isEnable: ConcertVisibleType = ALL;
    isDeleted: boolean = false;
    @observable public slugAvailable: boolean = true;
    @observable public fullAudioUrl: string | null = null
    allConcertTitles: ConcertTitle[] = [];
    isTestConcert: boolean = false
    Gpage: number = 1;
    Glimit: number = 10;
    GtotalItems: number = 0;
    GtotalPages: number = 0;
    Gstatus: PostStatus = ALL;
    GprocessingStatus: ProcessingStatus = ALL;



    private defaultFilters: DefaultFiltersForConcertTable = {
        searchTerm: '',
        concertType: 'All',
        concertStatus: 'All',
        limit: 10,
        isEnable: ALL,
        isTestConcert: false,
        page: 1,
        isDeleted: false
    };


    private defaultGalleryPage: resetGalleryPage = {
        Gpage: 1,
        Glimit: 10,
        GtotalItems: 0,
        GtotalPages: 0,
        Gstatus: ALL,
        GprocessingStatus: ALL,
    };

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllConcertData = async (
        action: 'change_page' | 'search' | 'change_filter' | '',
        page: number,
        searchTerm: string,
        limit: number,
        concertType: string,
        concertStatus: string,
        isEnable: ConcertVisibleType,
        isTestConcert: boolean,
        isDeleted: boolean
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_filter':
                        this.limit = limit;
                        this.concertType = concertType;
                        this.concertStatus = concertStatus;
                        this.isEnable = isEnable;
                        this.isTestConcert = isTestConcert
                        this.page = 1;
                        this.isDeleted = isDeleted;
                        break;
                    default:
                        break;
                }
            });
            const params: any = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm,
                concert_type: this.concertType.toUpperCase(),
                concert_status: this.concertStatus.toUpperCase(),
                is_test_concert: this.isTestConcert,
                is_deleted: this.isDeleted
            }
            if (isEnable === VISIBLE) {
                params.is_enable = true;
            } else if (isEnable === HIDDEN) {
                params.is_enable = false;
            }
            const response = await getAllConcertData(params);
            runInAction(() => {
                this.allConcertData = response.data?.data?.concerts || [];
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            });
        } catch (error: any) {
            if (error?.response?.status !== 404) {
                this.rootStore.authStore.handleUnauthorized(error);
            }
        } finally {
            this.setLoading(false);
        }
    }

    fetchOneConcertData = async (id: string) => {
        this.setLoading(true);
        try {
            const response = await getOneConcertData(id);
            const data = response.data?.data?.concert;

            runInAction(() => {
                this.concertData = this.formatConcertData(data);
                this.audioURL = data.file_url;
                this.concertDuration = data.length;
                this.fullAudioUrl = data.audio_url;
                this.trackSplitting = data.track_splitting || [];
            });
        } catch (error: any) {
            if (error?.response?.status !== 404) {
                this.rootStore.authStore.handleUnauthorized(error);
            }
        } finally {
            this.setLoading(false);
        }
    }

    updateConcertDetails = async (id: string, data: ConcertData) => {
        this.setLoading(true);
        try {
            const filteredData = this.filterEmptyFields(data);
            const getFileURLEndpoint = this.getUrlEndpoint(this.audioURL!)
            const requestBody: CreateConcertData = {
                ...filteredData as CreateConcertData,
                genre: data.genre.map((genre: Genre) => genre.value),
                type: data.type.value,
                file_url: getFileURLEndpoint !== undefined ? getFileURLEndpoint : null,
                length: this.concertDuration !== undefined ? this.concertDuration : null,
                concert_id: id,
                is_enable: data.is_enable,
                status: data.status.value,
                is_test: data.is_test,
                is_ugc_enable: data.is_ugc_enable || false,
                artist_slug: data.artist_slug,
                should_show_in_link_tree: data?.should_show_in_link_tree
            }
            const response = await updateConcertData(requestBody);
            runInAction(async () => {
                this.resetConcertData();
                const data = response.data?.data;
                const formattedGenre = data.genre.map((genre: string) => ({
                    value: genre,
                    label: genre.charAt(0).toUpperCase() + genre.slice(1)
                }));
                const formattedArtist = await Promise.all(data.credits.artists.map(async (artist: string) => {
                    const artist_res = await getOneUserData(artist);
                    const res = artist_res.data.data
                    return { value: res._id, label: res.name };
                }));
                const formattedPhotographer = await Promise.all(data.credits.photographers.map(async (photographer: string) => {
                    const photographer_res = await getOneUserData(photographer);
                    const res = photographer_res.data.data
                    return { value: res._id, label: res.name };
                }));
                const formattedVenue = await Promise.all(data.credits.venues.map(async (location: string) => {
                    const location_res = await getOneUserData(location);
                    const res = location_res.data.data
                    return { value: res._id, label: res.name };
                }));
                const formattedType = {
                    value: data.type,
                    label: data.type.charAt(0).toUpperCase() + data.type.slice(1).toLocaleLowerCase()
                }
                const formattedStatus = {
                    value: data.status,
                    label: data.status.charAt(0).toUpperCase() + data.status.slice(1).toLocaleLowerCase()
                }
                const date = new Date(data?.date);
                const formattedDate = date.toISOString().split('T')[0];
                runInAction(() => {
                    this.concertData = {
                        ...data,
                        genre: formattedGenre,
                        artist: formattedArtist,
                        location: formattedVenue,
                        photographer: formattedPhotographer,
                        type: formattedType,
                        date: formattedDate,
                        status: formattedStatus
                    };
                    this.audioURL = data.file_url;
                    this.concertDuration = data.length;
                    this.fullAudioUrl = data.audio_url;
                    this.trackSplitting = data.track_splitting || [];
                });
            });
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    addNewConcert = async (data: ConcertData): Promise<ConcertData> => {
        this.setLoading(true);
        try {
            const filteredData = this.filterEmptyFields(data);
            const requestBody: CreateConcertData = {
                ...filteredData as CreateConcertData,
                genre: data.genre.map((genre: Genre) => genre.value),
                type: data.type.value,
                file_url: this.getUrlEndpoint(this.audioURL!),
                length: this.concertDuration!,
                is_enable: data.is_enable,
                is_test: data.is_test,
                status: data.status.value,
                artist_slug: data.artist_slug,
                // should_show_in_link_tree: data.should_show_in_link_tree
            }
            const response = await createConcert(requestBody);
            runInAction(() => {
                this.resetConcertData();
                this.concertData = response.data?.data;
            });
            return response.data?.data;
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    resetConcertData = () => {
        this.concertData = null;
        this.trackSplitting = [];
        this.gallery = [];
        this.audioURL = null;
        this.concertDuration = null;
    }

    fetchSecretToken = async (file_name: string, content_type: string) => {
        try {
            const response = await getPreSignedUrlAudio(file_name, content_type);
            return response.data;
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }


    uploadAudio = async (file: File): Promise<string> => {
        this.isAudioUploading = true;
        this.audioUploadProgress = 0;
        this.audioUploadComplete = false;
        try {
            const response = await getPreSignedUrlAudio(file.name, file.type);
            const { presigned_url, file_name } = response.data.data;

            await uploadFileToS3(presigned_url, file, file.type);

            runInAction(() => {
                this.audioUploadComplete = true;
                this.audioURL = file_name;
            });
            return file_name;
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            throw error;
        } finally {
            runInAction(() => {
                this.isAudioUploading = false;
            });
        }
    }

    uploadImages = async (files: File[]): Promise<any> => {
        try {
            const allImages = files.map(file => ({ file_name: file.name, content_type: file.type }))
            const uploadedFileNames = await getPreSignedUrlImage(allImages);

            const uploadPromises = uploadedFileNames.data.data.map(async (image: { file_name: string, presigned_url: string, name: string }) => {
                const file = files.find(file => file.name === image.name);
                if (file) {
                    await uploadFileToS3(image.presigned_url, file, file.type);
                    return image.file_name;
                }
            });

            await Promise.all(uploadPromises);

            return uploadedFileNames.data.data;
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            throw error;
        }
    };

    updateTrackSplit = async (data: TrackSplittingType[]) => {
        this.setLoading(true);
        try {
            const requestBody = {
                track_splitting: data.map((track, index) => {
                    return {
                        ...track,
                        split_end_time: undefined,
                    }
                })
            }
            const response = await updateTrackSplitData(this.concertData!._id!, requestBody);
            runInAction(() => {
                this.trackSplitting = response.data.data;
            })
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateGalleryMedia = async (concertId: string, data: MediaItem[]) => {
        this.setLoading(true);
        try {
            const requestBody = {
                gallery: data
            }
            await updateGallery(concertId, requestBody);
            await this.fetchConcertGallery('', concertId, this.Gpage, this.Glimit, this.Gstatus, this.GprocessingStatus);
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    setConcertDuration = (duration: string) => {
        this.concertDuration = duration;
    }

    getUrlEndpoint = (fullUrl: string) => {
        try {
            const url = new URL(fullUrl);
            const endpoint = url.pathname.startsWith("/") ? url.pathname.slice(1) : url.pathname;
            return endpoint;
        } catch (error) {
            return fullUrl;
        }
    };

    fetchAllPriceForConcert = async () => {
        this.setLoading(true);
        try {
            const response = await getAllPricesForConcertData();
            runInAction(() => {
                this.priceDataForConcert = [
                    ...(response.data?.data?.length > 0
                        ? response.data.data.map((concert: { _id: string; price: number }) => ({
                            _id: concert._id,
                            title: `$${concert.price.toFixed(2)}`,
                        }))
                        : []
                    ),
                ]
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    checkSlugIsAvailable = async (date: string, artistId: string, id?: string): Promise<{
        slugAvailable: boolean,
        slug: string
    }> => {
        try {
            // convert date into dd-mm-yyyy format
            const formattedDate = new Date(date);
            const day = formattedDate.getUTCDate().toString().padStart(2, '0');
            const month = (formattedDate.getUTCMonth() + 1).toString().padStart(2, '0');
            const year = formattedDate.getUTCFullYear();
            const formattedDateString = `${month}-${day}-${year}`;
            const requestBody: verifySlugData = { date: formattedDateString, artist_id: artistId }
            if (id) {
                requestBody.id = id;
            }

            const response = await verifyUniqueSlug(requestBody);
            runInAction(() => {
                this.slugAvailable = response.data.success === 1
            });

            return {
                slugAvailable: response.data.success,
                slug: response.data.data.slug
            }
        } catch (error: any) {
            runInAction(() => {
                this.slugAvailable = false
            })
            this.rootStore.authStore.handleUnauthorized(error);
            return {
                slugAvailable: false,
                slug: ''
            };
        }
    }

    filterEmptyFields = (obj: Record<string, any>) => {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) =>
            v !== undefined && v !== null && (typeof v === 'string' ? v.trim() : v)
        ));
    };

    toggleEnableConcert = async (id: string) => {
        try {
            await toggleIsEnable(id);
            await this.fetchAllConcertData('', this.page, this.searchTerm, this.limit, this.concertType, this.concertStatus, this.isEnable, this.isTestConcert, this.isDeleted);
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            throw error;
        }
    }

    fetchAllConcertTitleList = async () => {
        try {
            const response = await getAllConcertTitles();
            this.allConcertTitles = response.data?.data;
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }

    resetConcertPageDataStates = () => {
        runInAction(() => {
            this.allConcertData = [];
            this.concertData = null;
            this.priceDataForConcert = []
            this.error = null;
            this.loading = false;
            this.page = 1;
            this.searchTerm = '';
            this.limit = 10;
            this.concertType = 'All';
            this.concertStatus = 'All';
            this.totalItems = 0;
            this.totalPages = 0;
            this.audioUploadProgress = 0;
            this.isAudioUploading = false;
            this.audioUploadComplete = false;
            this.audioURL = null;
            this.concertDuration = null;
            this.slugAvailable = false;
            this.allConcertTitles = [];
            this.isDeleted = false;
        })
    }

    fetchTotalUsers = async (id: string) => {
        try {
            const response = await getTotalUsers(id);
            return response.data?.data?.total_users;
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }

    resetFilters = (): void => {
        runInAction(() => {
            Object.assign(this, this.defaultFilters);
        });
        this.fetchAllConcertData('', this.page, this.searchTerm, this.limit, this.concertType, this.concertStatus, this.isEnable, this.isTestConcert, this.isDeleted);
    };

    isFilterApplied = (): boolean => {
        return Object.entries(this.defaultFilters).some(([key, defaultValue]) => {
            // Skip checking page property
            if (key === 'page') return false;

            const currentValue = this[key as keyof ConcertStore];
            return currentValue !== defaultValue;
        });
    };

    defaultConcertGalleryImage = async (concert_id: string, image_id: string) => {
        try {
            this.setLoading(true);
            await setDefaultGalleryImage(concert_id, image_id);
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    deleteConcertGalleryImage = async (concert_id: string, image_id: string) => {
        try {
            this.setLoading(true);
            await deleteGalleryImage(concert_id, image_id);
            runInAction(() => {
                // Set Page
                this.Gpage = this.gallery.length === 1 ? this.Gpage > 1 ? this.Gpage - 1 : this.Gpage : this.Gpage
                this.fetchConcertGallery('change_page', this.concertData?._id || '', this.Gpage, this.Glimit, this.Gstatus, this.GprocessingStatus)
            });
        } catch (error: any) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    resetGalleryPage = (): void => {
        runInAction(() => {
            Object.assign(this, this.defaultGalleryPage);
        });
    };

    resetGalleryImages = (gallery: MediaItem[] | []): void => {
        runInAction(() => {
            this.gallery = gallery;
        });
    };

    updateFeedPostUser = async (concertId: string, postId: string, userId: string) => {
        try {
            this.setLoading(true);
            const data = {
                post_id: postId,
                user_id: userId,
            }
            await assignUserToFeedPost(data);
            await this.fetchConcertGallery('', concertId, this.Gpage, this.Glimit, this.Gstatus, this.GprocessingStatus);
        } catch (error: any) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    approvePost = async (concertId: string, postId: string) => {
        try {
            this.setLoading(true);
            await approveFeedPost(postId);
            await this.fetchConcertGallery('', concertId, this.Gpage, this.Glimit, this.Gstatus, this.GprocessingStatus);
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    rejectPost = async (concertId: string, postId: string) => {
        try {
            this.setLoading(true);
            await rejectFeedPost(postId);
            await this.fetchConcertGallery('', concertId, this.Gpage, this.Glimit, this.Gstatus, this.GprocessingStatus);
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    fetchConcertGallery = async (action: 'change_page' | 'change_filter' | '', id: string, page: number, limit: number, postStatus: PostStatus, processingStatus: ProcessingStatus) => {
        try {
            switch (action) {
                case 'change_page':
                    this.Gpage = page;
                    break;
                case 'change_filter':
                    this.Glimit = limit;
                    this.Gpage = 1;
                    this.Gstatus = postStatus;
                    this.GprocessingStatus = processingStatus;
                    break;
                default:
                    break;
            }
            const params: any = {
                page: this.Gpage,
                limit: this.Glimit,
                post_status: this.Gstatus,
                processing_status: this.GprocessingStatus
            }

            const response = await getConcertGallery(id, params);
            const concertData = response.data?.data?.concert_data;

            runInAction(() => {
                this.concertData = this.formatConcertData(concertData);
                this.gallery = response.data?.data?.gallery || [];
                this.GtotalItems = response.data?.data?.totalItems;
                this.GtotalPages = response.data?.data?.totalPages;
                this.fullAudioUrl = concertData.audio_url;
                this.concertDuration = concertData.length;
                this.audioURL = concertData.file_url;
                this.trackSplitting = concertData.track_splitting || [];
            });
        } catch (error: any) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    fetchConcertTrackSplitData = async (concertId: string) => {
        try {
            const response = await getConcertTrackSplitData(concertId);
            const concertData = response.data?.data?.concert_data;

            runInAction(() => {
                this.concertData = this.formatConcertData(concertData);
                this.trackSplitting = response.data?.data?.track_splitting || [];
                this.gallery = response.data?.data?.gallery || [];
                this.GtotalItems = response.data?.data?.totalItems;
                this.GtotalPages = response.data?.data?.totalPages;
                this.fullAudioUrl = concertData.audio_url;
                this.concertDuration = concertData.length;
                this.audioURL = concertData.file_url;
            });
        } catch (error: any) {
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }

    removeConcert = async (concertId: string) => {
        try {
            await deleteConcert(concertId);
            if (this.page > 1 && this.allConcertData.length === 1) {
                this.page = this.page - 1;
            }
            await this.fetchAllConcertData('', this.page, this.searchTerm, this.limit, this.concertType, this.concertStatus, this.isEnable, this.isTestConcert, this.isDeleted);
        } catch (error: any) {
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }

    updateWeight = async (concertId: string, weight: number) => {
        try {
            await updateConcertWeight({ concert_id: concertId, weight });
            await this.fetchAllConcertData('', this.page, this.searchTerm, this.limit, this.concertType, this.concertStatus, this.isEnable, this.isTestConcert, this.isDeleted);
        } catch (error: any) {
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }

    // Format concert data for the form
    private formatConcertData = (data: any) => {
        const formattedGenre = data.genre.map((genre: string) => ({
            value: genre,
            label: genre.charAt(0).toUpperCase() + genre.slice(1)
        }));

        const formattedArtist = data.credits.artists.map((artist: any) => ({
            value: artist._id,
            label: artist.name
        }));

        const formattedPhotographer = data.credits.photographers.map((photographer: any) => ({
            value: photographer._id,
            label: photographer.name
        }));

        const formattedVenue = data.credits.venues.map((location: any) => ({
            value: location._id,
            label: location.name
        }));

        const formattedType = {
            value: data.type,
            label: data.type.charAt(0).toUpperCase() + data.type.slice(1).toLowerCase()
        };

        const formattedStatus = {
            value: data.status,
            label: data.status.charAt(0).toUpperCase() + data.status.slice(1).toLowerCase()
        };

        const date = new Date(data?.date);
        const formattedDate = date.toISOString().split('T')[0];

        return {
            ...data,
            genre: formattedGenre,
            artist: formattedArtist,
            location: formattedVenue,
            photographer: formattedPhotographer,
            type: formattedType,
            date: formattedDate,
            status: formattedStatus
        };
    };

    updateMediaSettings = async (mediaId: string, mediaSettings: MediaSettings) => {
        try {
            const data = {
                media_settings: mediaSettings,
                media_id: mediaId,
                concert_id: this.concertData?._id || ''
            }
            await saveMediaSettings(data);
            await this.fetchConcertGallery('', this.concertData?._id || '', this.Gpage, this.Glimit, this.Gstatus, this.GprocessingStatus);
        } catch (error: any) {
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }

}

export default ConcertStore;
