import { makeAutoObservable, runInAction } from "mobx";
import { IVenueStore, Store, CreateVenueData, UserData, AllUserData, CommonDefaultFilters, UpdateVenueData } from "../global/types";
import { checkIfSlugIsAvailable, createVenue, getAllVenueData, getOneUserData, getPreSignedImageUrl, updateVenueData, uploadFileToS3 } from "../api/user.action";
import { deleteCredit } from "../api/credit.action";
import { VENUE } from "../utils/constant";

class VenueStore implements IVenueStore {
    rootStore: Store;
    allVenueData: AllUserData[] = [];
    venueData: UserData | null = null;
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    totalItems: number = 0;
    totalPages: number = 0;

    private defaultFiltersForVenue: CommonDefaultFilters = {
        page: 1,
        limit: 10,
        searchTerm: ''
    }

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllVenueData = async (
        action: 'change_page' | 'search' | 'change_limit' | '',
        page: number,
        searchTerm: string,
        limit: number
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_limit':
                        this.limit = limit
                        this.page = 1
                        break;
                    default:
                        break;
                }
            })
            const data = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm?.trim()
            }
            const response = await getAllVenueData(data);
            runInAction(() => {
                this.allVenueData = response.data?.data?.users || [];
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    fetchOneVenueData = async (id: string) => {
        this.setLoading(true);
        try {
            const response = await getOneUserData(id);
            runInAction(() => {
                this.venueData = response.data.data;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateVenueDetails = async (data: CreateVenueData) => {
        this.setLoading(true);
        try {
            const requestBody: UpdateVenueData = {
                name: data.name,
                city: data.city,
                state: data.state,
                image: this.getUrlEndpoint(data.image),
                venue_id: this.venueData?._id!,
                timezone: data.timezone
            }
            if (data.bio) {
                requestBody['bio'] = data.bio;
            }

            const response = await updateVenueData(requestBody);
            runInAction(() => {
                this.venueData = response.data.data;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    addNewVenue = async (data: CreateVenueData) => {
        this.setLoading(true);
        try {
            const requestBody: CreateVenueData = {
                name: data.name,
                city: data.city,
                state: data.state,
                image: data.image,
                timezone: data.timezone,
                slug: data.slug
            }
            if (data.bio) {
                requestBody['bio'] = data.bio;
            }

            const response = await createVenue(requestBody);
            runInAction(() => {
                this.venueData = response.data.data;
            })
            return response.data.data
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    deleteVenue = async (id: string) => {
        try {
            await deleteCredit(id, VENUE);
            if (this.page > 1 && this.allVenueData.length === 1) {
                runInAction(() => {
                    this.page -= 1;
                })
            }
            await this.fetchAllVenueData('', this.page, this.searchTerm, this.limit);
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }

    verifyIfSlugIsAvailable = async (slug: string, venueId?: string) => {
        try {
            const data: {
                slug: string,
                credit_id?: string
            } = { slug };

            if (venueId) {
                data['credit_id'] = venueId;
            }
            await checkIfSlugIsAvailable(data);
            return { isAvailable: true };
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            return { isAvailable: false };
        }
    }

    resetVenuePageDataStates = () => {
        runInAction(() => {
            this.allVenueData = [];
            this.venueData = null;
            this.error = null;
            this.loading = false;
            this.page = 1;
            this.searchTerm = '';
            this.limit = 10;
            this.totalItems = 0;
            this.totalPages = 0;
        })
    }

    getUrlEndpoint = (fullUrl: string) => {
        try {
            const url = new URL(fullUrl);
            const endpoint = url.pathname.startsWith("/") ? url.pathname.slice(1) : url.pathname;
            return endpoint;
        } catch (error) {
            return fullUrl;
        }
    };

    uploadImage = async (file: File, user_type: string): Promise<string> => {
        try {
            const response = await getPreSignedImageUrl(file.name, file.type, user_type);
            const { presigned_url, file_name } = response.data.data;

            await uploadFileToS3(presigned_url, file, file.type);

            return file_name;
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            throw error;
        }
    }

    resetFilters = () => {
        runInAction(() => {
            Object.assign(this, this.defaultFiltersForVenue);
        })
        this.fetchAllVenueData('', this.page, this.searchTerm, this.limit);
    }

    isFilterApplied = () => {
        return Object.entries(this.defaultFiltersForVenue).some(([key, defaultValue]) => {
            // Skip checking page property
            if (key === 'page') return false;

            const currentValue = this[key as keyof VenueStore];
            return currentValue !== defaultValue;
        });
    }
}

export default VenueStore;
